import { useContext, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import { Logout, Settings } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Menu, MenuItem, Tooltip } from "@mui/material";

import AuthContext from "../AuthContext";
import { signOut } from "firebase/auth";

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "linear-gradient(to right, #2c3e50, #34495e)",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function ScratchieWrapper(props) {
  const { auth, isAuth, isAuthVerified, isLoadingAuth } =
    useContext(AuthContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  const handleProfileMenuClick = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSideBarRedirect = (url) => {
    navigate(url);
  };

  const handleLogoutClose = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleCloser = () => {
    setIsOpen(false);
    window.location.href = "/";
  };

  const handleLogoutWorkflow = async () => {
    try {
      await signOut(auth);
      setIsOpen(true);
      handleProfileMenuClose();
    } catch (err) {
      alert("Unable to sign out");
    }
  };

  useEffect(() => {
    if (isAuth) {
      if (props.photoUrl !== null && props.photoUrl !== "") {
        setPhotoUrl(props.photoUrl);
      }
    }
  }, [props.photoUrl]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 600,
              color: "white",
              textDecoration: "none",
              textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
              fontSize: "1.4rem",
              textTransform: "uppercase",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            Scratchie HUB
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <Tooltip title="My Account">
              <IconButton
                onClick={handleProfileMenuClick}
                aria-controls={isProfileMenuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isProfileMenuOpen ? "true" : undefined}
              >
                {!photoUrl && (
                  <AccountCircleIcon
                    sx={{ width: 45, height: 45, color: "white" }}
                  />
                )}
                {photoUrl && (
                  <img
                    style={{ width: 45, height: 45, borderRadius: "50%" }}
                    src={photoUrl}
                    alt="Profile"
                  />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={profileMenuAnchorEl}
              id="account-menu"
              open={isProfileMenuOpen}
              onClose={handleProfileMenuClose}
              onClick={handleProfileMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {isAuth && (
                <div>
                  {/* <MenuItem onClick={() => {handleProfileMenuClose(); handleSideBarRedirect("/profile")}}>
                <ListItemIcon>
                    <Settings fontSize='medium' sx={{color:'black', backgroundColor:'none'}}/> 
                </ListItemIcon>
                Account
            </MenuItem> */}
                  <MenuItem onClick={() => handleSideBarRedirect("/profile")}>
                    <ListItemIcon>
                      <AccountCircleIcon
                        fontSize="medium"
                        sx={{ color: "black" }}
                      />
                    </ListItemIcon>
                    My Profile
                  </MenuItem>
                  <MenuItem onClick={() => setIsLogoutDialogOpen(true)}>
                    <ListItemIcon>
                      <Logout fontSize="medium" sx={{ color: "black" }} />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </div>
              )}
              {!isAuth && (
                <MenuItem
                  onClick={() => {
                    handleProfileMenuClose();
                    handleSideBarRedirect("/login");
                  }}
                >
                  <ListItemIcon>
                    <LoginIcon
                      fontSize="medium"
                      sx={{ color: "black", backgroundColor: "none" }}
                    />
                  </ListItemIcon>
                  Sign In
                </MenuItem>
              )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Tooltip title="Home" placement="right">
              <ListItemButton
                onClick={() => {
                  handleSideBarRedirect("/");
                }}
                sx={{
                  minHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HomeIcon sx={{ color: "black" }} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography style={{ fontFamily: "Times New Roman" }}>
                      Home
                    </Typography>
                  }
                  sx={{ opacity: open ? 1 : 0, fontWeight: 10 }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
          <Divider />
          {!isAuth && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <Tooltip title="Log In" placement="right">
                <ListItemButton
                  onClick={() => {
                    handleSideBarRedirect("/login");
                  }}
                  sx={{
                    minHeight: 50,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LoginIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography style={{ fontFamily: "Times New Roman" }}>
                        Log In
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0, fontWeight: 10 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
          {isAuth && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <Tooltip title="My Profile" placement="right">
                <ListItemButton
                  onClick={() => {
                    handleSideBarRedirect("/profile");
                  }}
                  sx={{
                    minHeight: 50,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AccountCircleIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography style={{ fontFamily: "Times New Roman" }}>
                        My Profile
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0, fontWeight: 10 }}
                  />
                </ListItemButton>
              </Tooltip>
              <Tooltip title="Log Out" placement="right">
                <ListItemButton
                  onClick={() => {
                    setIsLogoutDialogOpen(true);
                  }}
                  sx={{
                    minHeight: 50,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <LogoutIcon sx={{ color: "black" }} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography style={{ fontFamily: "Times New Roman" }}>
                        Logout
                      </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0, fontWeight: 10 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          )}
        </List>
        <Divider />
        <List>
          {/* {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))} */}
        </List>
      </Drawer>
      {!props.isScroll && (
        <Main open={open}>
          <DrawerHeader />
          {props.element}
        </Main>
      )}
      {props.isScroll && (
        <Main
          open={open}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <DrawerHeader />
          {/* <div style={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}> */}
          {props.element}
          {/* </div> */}
        </Main>
      )}

      <Dialog open={isOpen} onClose={handleCloser}>
        <DialogTitle>Logout Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have just logged out of your Scratchie Hub account. Come back
            soon!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "rgba(0,0,0,.8)" }}
            onClick={handleCloser}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isLogoutDialogOpen} onClose={handleLogoutClose}>
        <DialogTitle>Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you would like to logout?
          </DialogContentText>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 25 }}
          >
            <Button
              color="primary"
              variant="contained"
              style={{ height: 50, width: 150, marginRight: 15 }}
              onClick={handleLogoutClose}
            >
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{ height: 50, width: 150 }}
              onClick={handleLogoutWorkflow}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "rgba(0,0,0,.8)" }}
            onClick={handleLogoutClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
