import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getMainTableData,
  getMasterSearch,
  getTicketsByState,
  getUserProfile,
} from "../ScratchieExpress";
import {
  Paper,
  InputBase,
  Grid,
  Divider,
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  Slider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TicketCard from "./TicketCard";
import { Container, Row, Col } from "reactstrap";
import { STATES } from "../data/StateAbbreviations";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMediaPredicate } from "react-media-hook";
import TuneIcon from "@mui/icons-material/Tune";

import { setGlobalHistory } from "../redux/actions/historyActions";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { setGlobalFeed } from "../redux/actions/feedActions";

import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import AuthContext from "../AuthContext";

function LandingPage() {
  const { auth, isAuth, isAuthVerified, isLoadingAuth } =
    useContext(AuthContext);
  const [isStateDoneLoading, setIsStateDoneLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(null);
  const [state, setState] = useState(null);
  const [stateQuery, setStateQuery] = useState(null);
  const [checkedPriceValues, setCheckedPriceValues] = useState([]);
  const [roiSort, setRoiSort] = useState("descending");
  const [netExpectedSort, setNetExpectedSort] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const dispatch = useDispatch();
  const globalFeed = useSelector((state) => state.globalFeed);
  const globalHistory = useSelector((state) => state.history);

  const RealMobileScreen = useMediaPredicate("(max-width: 767px)");
  const MobileScreen = useMediaPredicate("(max-width: 991px)");
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const mainContentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0); // State to store the scroll position
  const [restoredScrollPosition, setRestoredScrollPosition] = useState(0);

  const handleAlertMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleToggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  const executeSearch = async (query, state, price, roi, netExpectedSort) => {
    try {
      setIsLoading(true);
      const scratchyData = await getMasterSearch(
        query,
        state,
        price,
        roi,
        netExpectedSort
      );
      setRows(scratchyData);
    } catch (err) {
      handleAlertMessage("Error retrieving tickets. Please reload page.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleQueryChange = (event) => {
    const target = event.target;
    const value = target.value;
    setQuery(value);
    executeSearch(value, stateQuery, checkedPriceValues, roiSort);
  };

  const handleStateChange = (event) => {
    let value = event;
    let localState = null;
    if (value) {
      localState = value.label;
      setStateQuery(value.label);
    } else {
      setStateQuery(null);
    }
    setState(value);

    executeSearch(query, localState, checkedPriceValues, roiSort);
  };

  const handleTicketClick = (uuid, state) => {
    handleUrlRedirect("/ticket/" + state + "/" + uuid);
    navigate("/ticket/" + state + "/" + uuid);
  };

  const handlePriceChange = (event) => {
    const { value, checked } = event.target;
    const priceValue = value.replace("$", "");

    let updatedValues;

    if (checked) {
      updatedValues = [...checkedPriceValues, priceValue];
    } else {
      updatedValues = checkedPriceValues.filter((item) => item !== priceValue);
    }

    setCheckedPriceValues(updatedValues);
    executeSearch(query, stateQuery, updatedValues, roiSort, netExpectedSort);
  };

  const handleNetExpectedSortChange = (order) => {
    console.log(order);
    let localNetExpected = null;
    if (netExpectedSort === order) {
      setNetExpectedSort(null);
      executeSearch(
        query,
        stateQuery,
        checkedPriceValues,
        roiSort,
        localNetExpected
      );
    } else {
      setRoiSort(null);
      localNetExpected = order;
      setNetExpectedSort(order);
      executeSearch(
        query,
        stateQuery,
        checkedPriceValues,
        null,
        localNetExpected
      );
    }
  };

  const handleRoiSortChange = (order) => {
    let localRoi = null;
    if (roiSort === order) {
      setRoiSort(null);
      executeSearch(
        query,
        stateQuery,
        checkedPriceValues,
        localRoi,
        netExpectedSort
      );
    } else {
      setNetExpectedSort(null);
      localRoi = order;
      setRoiSort(order);
      executeSearch(query, stateQuery, checkedPriceValues, localRoi, null);
    }
  };

  const initializePage = async () => {
    try {
      setIsLoading(true);
      let stateQuery = null;
      if (isAuth) {
        let userData = await getUserProfile(auth.currentUser.uid);
        if (userData.state) {
          let stateTuple = STATES.find(
            (state) => state.value === userData.state
          );
          setStateQuery(stateTuple.label);
          setState(stateTuple);
          stateQuery = stateTuple.label;
        }
      }
      const scratchyData = await getMasterSearch(
        null,
        stateQuery,
        null,
        "descending",
        null
      );
      setRows(scratchyData);
    } catch (err) {
      handleAlertMessage("Error retrieving tickets. Please reload page.");
    } finally {
      setIsLoading(false);
    }
  };

  const restorePage = async (pageData) => {
    try {
      const deepCopyGlobalFeed = globalFeed.map((obj) => _.cloneDeep(obj));
      setRows((prevRows) => [...prevRows, ...deepCopyGlobalFeed]);

      if (pageData["query"]) {
        setQuery(pageData["query"]);
      }

      if (pageData["state"]) {
        setState(pageData["state"]);
      }

      if (pageData["checkedPriceValues"]) {
        setCheckedPriceValues(pageData["checkedPriceValues"]);
      }

      if (pageData["roiSort"]) {
        setRoiSort(pageData["roiSort"]);
      }

      if (pageData["netExpectedSort"]) {
        setNetExpectedSort(pageData["netExpectedSort"]);
      }

      if (pageData["isAccordionOpen"]) {
        setIsAccordionOpen(pageData["isAccordionOpen"]);
      }
    } catch (err) {
      console.log(err);
      handleAlertMessage(
        "Error retrieving data. Please reload the page.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isStateDoneLoading) {
      const currentURL = location.pathname + location.search;
      if (globalFeed.length === 0) {
        initializePage();
      } else {
        if (
          globalHistory.length !== 0 &&
          globalHistory[globalHistory.length - 1]["url"] === currentURL
        ) {
          const historyDetails = _.cloneDeep(
            globalHistory[globalHistory.length - 1]
          );
          restorePage(historyDetails["pageData"]);
          if (historyDetails && historyDetails["scrollPosition"]) {
            setRestoredScrollPosition(historyDetails["scrollPosition"]);
          }
          const updatedHistory = _.cloneDeep(globalHistory.slice(0, -1));
          dispatch(setGlobalHistory(updatedHistory));
        }
      }
    }
  }, [isStateDoneLoading]);

  useEffect(() => {
    if (!isStateDoneLoading) {
      if (
        globalHistory !== undefined &&
        globalFeed !== undefined &&
        !isLoadingAuth
      ) {
        setIsStateDoneLoading(true);
      }
    }
  }, [globalHistory, globalFeed, isLoadingAuth]);

  useEffect(() => {
    restoreScroll();
  }, [restoredScrollPosition]);

  const handleMainContentScroll = () => {
    if (mainContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainContentRef.current;
      setScrollPosition(scrollTop); // Update the scroll position state
    }
  };

  const restoreScroll = () => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = restoredScrollPosition;
    }
  };

  const handleUrlRedirect = (desiredUrl) => {
    const currentURL = location.pathname + location.search;
    if (currentURL !== desiredUrl) {
      const currentScrollPosition = scrollPosition;
      let historyObj = {
        url: currentURL,
        scrollPosition: currentScrollPosition,
        pageData: {
          query: _.cloneDeep(query),
          state: _.cloneDeep(state),
          checkedPriceValues: _.cloneDeep(checkedPriceValues),
          roiSort: _.cloneDeep(roiSort),
          netExpectedSort: _.cloneDeep(netExpectedSort),
          isAccordionOpen: _.cloneDeep(isAccordionOpen),
        },
      };

      dispatch(setGlobalHistory([...globalHistory, historyObj]));

      if (rows && rows.length > 0) {
        const deepCopyLocalFeed = rows.map((obj) => _.cloneDeep(obj));
        dispatch(setGlobalFeed(deepCopyLocalFeed));
      }
    }
  };

  const priceOptions = [1, 2, 3, 5, 10, 20, 30, 50, 100].map((price) => (
    <FormControlLabel
      key={price}
      control={<Checkbox value={`$${price}`} onChange={handlePriceChange} />}
      label={`$${price}`}
    />
  ));

  return (
    <div style={{ maxWidth: "100%", width: "100%", overflow: "hidden" }}>
      <div
        ref={mainContentRef}
        onScroll={handleMainContentScroll}
        style={{ height: "100%", overflowY: "scroll", maxWidth: "100%" }}
      >
        <Container>
          <Row>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ paddingTop: 25 }}
            >
              {!RealMobileScreen && (
                <Paper
                  className="px-3"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 60,
                    elevation: 16,
                    borderRadius: 15,
                  }}
                >
                  <Grid
                    container
                    sx={{ flex: 1 }}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xs={5.9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <InputBase
                        startAdornment={
                          <SearchIcon style={{ marginRight: 5 }} />
                        }
                        sx={{
                          width: "100%",
                          fontFamily: "Times New Roman",
                          fontSize: 20,
                        }}
                        placeholder="Search by ticket"
                        type="search"
                        onChange={handleQueryChange}
                        value={query}
                      />
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ height: 60, borderColor: "gray" }}
                    />
                    <Grid
                      item
                      xs={5.9}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <Autocomplete
                        id="state"
                        name="state"
                        options={STATES}
                        value={state}
                        getOptionLabel={(option) => option.value}
                        onChange={(event, selectedOption) => {
                          handleStateChange(selectedOption);
                        }}
                        fullWidth
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search by state"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationOnOutlinedIcon
                                    style={{ color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                              sx: {
                                "& .MuiFilledInput-root": {
                                  backgroundColor: "transparent",
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  paddingLeft: 0,
                                  fontSize: 18,
                                },
                              },
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                borderRadius: "4px",
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {RealMobileScreen && (
                <Paper
                  className="px-3"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 60,
                    elevation: 16,
                    borderRadius: 15,
                  }}
                >
                  <InputBase
                    startAdornment={<SearchIcon style={{ marginRight: 5 }} />}
                    sx={{
                      width: "100%",
                      fontFamily: "Times New Roman",
                      fontSize: 20,
                    }}
                    placeholder="Search by ticket"
                    type="search"
                    onChange={handleQueryChange}
                    value={query}
                  />
                </Paper>
              )}
            </Col>
          </Row>
          {!MobileScreen && (
            <Row style={{ marginTop: 5 }}>
              <Col xs="12" sm="12" md="12" lg="12" xl="12">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      marginLeft: 1,
                      fontWeight: "bold",
                      color: "#333",
                      textTransform: "none",
                      fontSize: 20,
                    }}
                  >
                    Results ({rows && rows.length})
                  </Typography>
                  <div style={{ flex: "1 0 0" }} />
                  <Button
                    onClick={handleToggleAccordion}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <TuneIcon sx={{ color: "#333", fontSize: 24 }} />
                    <Typography
                      sx={{
                        marginLeft: 1,
                        fontWeight: "bold",
                        color: "#333",
                        textTransform: "none",
                        fontSize: 18,
                      }}
                    >
                      {isAccordionOpen ? "Hide Filters" : "Show Filters"}
                    </Typography>
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg={isAccordionOpen ? "3" : "12"}
              xl={isAccordionOpen ? "3" : "12"}
              style={{
                borderRight:
                  isAccordionOpen && !MobileScreen
                    ? "1px solid #C2C2C2"
                    : "none",
              }}
            >
              {isAccordionOpen && !MobileScreen && (
                <div style={{ marginTop: 15 }}>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "inherit",
                      margin: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="price-range-content"
                      id="price-range-header"
                      sx={{ backgroundColor: "inherit" }}
                    >
                      <Typography>Price</Typography>
                    </AccordionSummary>
                    {priceOptions}
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "inherit",
                      margin: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="roi-sort-content"
                      id="roi-sort-header"
                      sx={{ backgroundColor: "inherit" }}
                    >
                      <Typography>Net Expected Value</Typography>
                    </AccordionSummary>
                    <div style={{ width: "100%" }}>
                      <Button
                        variant={
                          netExpectedSort === "ascending"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleNetExpectedSortChange("ascending")}
                        sx={{ width: "100%" }}
                      >
                        Ascending
                      </Button>
                    </div>
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <Button
                        variant={
                          netExpectedSort === "descending"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleNetExpectedSortChange("descending")
                        }
                        sx={{ width: "100%" }}
                      >
                        Descending
                      </Button>
                    </div>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "inherit",
                      margin: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="roi-sort-content"
                      id="roi-sort-header"
                      sx={{ backgroundColor: "inherit" }}
                    >
                      <Typography>ROI</Typography>
                    </AccordionSummary>
                    <div style={{ width: "100%" }}>
                      <Button
                        variant={
                          roiSort === "ascending" ? "contained" : "outlined"
                        }
                        onClick={() => handleRoiSortChange("ascending")}
                        sx={{ width: "100%" }}
                      >
                        Ascending
                      </Button>
                    </div>
                    <div style={{ width: "100%", marginTop: 10 }}>
                      <Button
                        variant={
                          roiSort === "descending" ? "contained" : "outlined"
                        }
                        onClick={() => handleRoiSortChange("descending")}
                        sx={{ width: "100%" }}
                      >
                        Descending
                      </Button>
                    </div>
                  </Accordion>
                </div>
              )}
              {MobileScreen && isAccordionOpen && (
                <div style={{ marginTop: 10 }}>
                  <Paper
                    className="px-3 py-3"
                    sx={{ width: "100%", elevation: 16, borderRadius: 3 }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          marginLeft: 1,
                          fontWeight: "bold",
                          color: "#333",
                          textTransform: "none",
                          fontSize: 20,
                        }}
                      >
                        Results ({rows && rows.length})
                      </Typography>
                      <div style={{ flex: "1 0 0" }} />
                      <Button
                        onClick={handleToggleAccordion}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <TuneIcon sx={{ color: "#333", fontSize: 24 }} />
                        <Typography
                          sx={{
                            marginLeft: 1,
                            fontWeight: "bold",
                            color: "#333",
                            textTransform: "none",
                            fontSize: 18,
                          }}
                        >
                          {isAccordionOpen ? "Hide Filters" : "Show Filters"}
                        </Typography>
                      </Button>
                    </div>
                    <div>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          id="state"
                          sx={{ backgroundColor: "inherit" }}
                        >
                          <Typography>State</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "inherit" }}>
                          <Autocomplete
                            id="state"
                            name="state"
                            options={STATES}
                            value={state}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, selectedOption) =>
                              handleStateChange(selectedOption)
                            }
                            variant="outlined"
                            fullWidth
                            disablePortal
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search by state"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LocationOnOutlinedIcon
                                        style={{ color: "black" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="price-range-content"
                          id="price-range-header"
                          sx={{ backgroundColor: "inherit" }}
                        >
                          <Typography>Price</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "inherit" }}>
                          {priceOptions}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="roi-sort-content"
                          id="roi-sort-header"
                          sx={{ backgroundColor: "inherit" }}
                        >
                          <Typography>Net Expected Value</Typography>
                        </AccordionSummary>
                        <div style={{ width: "100%" }}>
                          <Button
                            variant={
                              netExpectedSort === "ascending"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleNetExpectedSortChange("ascending")
                            }
                            sx={{ width: "100%" }}
                          >
                            Ascending
                          </Button>
                        </div>
                        <div style={{ width: "100%", marginTop: 10 }}>
                          <Button
                            variant={
                              netExpectedSort === "descending"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              handleNetExpectedSortChange("descending")
                            }
                            sx={{ width: "100%" }}
                          >
                            Descending
                          </Button>
                        </div>
                      </Accordion>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="roi-sort-content"
                          id="roi-sort-header"
                          sx={{ backgroundColor: "inherit" }}
                        >
                          <Typography>ROI</Typography>
                        </AccordionSummary>
                        <div style={{ width: "100%" }}>
                          <Button
                            variant={
                              roiSort === "ascending" ? "contained" : "outlined"
                            }
                            onClick={() => handleRoiSortChange("ascending")}
                            sx={{ width: 200 }}
                          >
                            Ascending
                          </Button>
                        </div>
                        <div style={{ width: "100%", marginTop: 10 }}>
                          <Button
                            variant={
                              roiSort === "descending"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => handleRoiSortChange("descending")}
                            sx={{ width: 200 }}
                          >
                            Descending
                          </Button>
                        </div>
                      </Accordion>
                    </div>
                  </Paper>
                </div>
              )}
              {MobileScreen && !isAccordionOpen && (
                <div style={{ marginTop: 5 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        marginLeft: 1,
                        fontWeight: "bold",
                        color: "#333",
                        textTransform: "none",
                        fontSize: 20,
                      }}
                    >
                      Results ({rows && rows.length})
                    </Typography>
                    <div style={{ flex: "1 0 0" }} />
                    <Button
                      onClick={handleToggleAccordion}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TuneIcon sx={{ color: "#333", fontSize: 24 }} />
                      <Typography
                        sx={{
                          marginLeft: 1,
                          fontWeight: "bold",
                          color: "#333",
                          textTransform: "none",
                          fontSize: 18,
                        }}
                      >
                        {isAccordionOpen ? "Hide Filters" : "Show Filters"}
                      </Typography>
                    </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg={isAccordionOpen ? "9" : "12"}
              xl={isAccordionOpen ? "9" : "12"}
            >
              {!isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Row>
                    {rows.map((ticket) => (
                      <Col
                        xs="12"
                        sm={
                          rows.length === 1
                            ? "12"
                            : rows.length === 2
                            ? "6"
                            : "6"
                        }
                        md={
                          rows.length === 1
                            ? "12"
                            : rows.length === 2
                            ? "6"
                            : "6"
                        }
                        lg={
                          rows.length === 1
                            ? "12"
                            : rows.length === 2
                            ? "6"
                            : "4"
                        }
                        key={ticket.id}
                        className="mb-4"
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <TicketCard
                            currentTicket={ticket}
                            handleTicketClick={handleTicketClick}
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {isLoading && (
                <div>
                  <CircularProgress
                    style={{ width: 100, height: 100, marginTop: 150 }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LandingPage;
