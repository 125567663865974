import React, {
  useState,
  useEffect,
  forwardRef,
  useContext,
  useRef,
} from "react";
import { Container, Row, Col, Input } from "reactstrap";

import TextField from "@mui/material/TextField";

import { signOut } from "firebase/auth";
import Button from "@mui/material/Button";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";

import {
  getDatabase,
  onValue,
  child,
  get,
  set,
  ref,
  update,
  remove,
} from "firebase/database";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import AuthContext from "../AuthContext";

import { SnackbarProvider, useSnackbar } from "notistack";

import {
  Autocomplete,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { getUserProfile, updateUserProfile } from "../ScratchieExpress";
import { ALLSTATES } from "../data/AllStateAbbreviations";
import EmailIcon from "@mui/icons-material/Email";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useMediaPredicate } from "react-media-hook";
import DirectionsIcon from "@mui/icons-material/Directions";
import IconButton from "@mui/material/IconButton";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
const validatePhoneNumber = (number) => {
  return String(number).match(
    /^(?:\+?1[-.\s]?)?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/
  );
};

const validateStreetAddress = (address) => {
  return String(address).match(/^\d+\s[A-z]+\s[A-z]+/);
};

const validateZipCode = (zip) => {
  return String(zip).match(/^\d{5}(?:[-\s]\d{4})?$/);
};

function Profile(props) {
  const { auth, isAuth, isAuthVerified, isLoadingAuth } =
    useContext(AuthContext);

  const [isDisabled, setIsDisabled] = useState(true);
  const [userData, setUserData] = useState({
    first_name: null,
    middle_initial: null,
    last_name: null,
    phone_number: null,
    email: null,
    address: null,
    state: null,
    city: null,
    zip: null,
  });
  const [isUserDataValid, setIsUserDataValid] = useState({
    first_name: true,
    middle_initial: true,
    last_name: true,
    phone_number: true,
    email: true,
    address: true,
    state: true,
    city: true,
    zip: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  // const [isEmailValid, setIsEmailValid] = useState(true)

  const [isResetOpen, setIsResetOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isEmailVerificationOpen, setIsEmailVerificationOpen] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isUserInvalid, setIsUserInvalid] = useState(null);

  const [photoSource, setPhotoSource] = useState(null);
  const [hasLoggedVisit, setHasLoggedVisit] = useState(false);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isSignedInWithGoogle, setIsSignedInWithGoogle] = useState(false);

  const retrieveUserDataBackup = async () => {
    console.log("Getting Backup");
    try {
      let savedUserData = await getUserProfile(auth.currentUser.uid);
      if (!savedUserData) {
        const userBackupDataString = localStorage.getItem("user_backup");

        if (userBackupDataString) {
          const userBackupData = JSON.parse(userBackupDataString);
          await updateUserProfile(auth.currentUser.uid, userBackupData);
          setUserData((prevState) => ({ ...prevState, ...userBackupData }));
          localStorage.removeItem("user_backup");
        } else {
          let user = auth.currentUser;
          let first_name = null;
          let last_name = null;
          let email = null;
          let phone_number = null;
          let photo_url = null;
          if (user.displayName) {
            const nameParts = user.displayName.split(" ");
            first_name = nameParts[0];
            last_name = nameParts.slice(1).join(" ");
          }

          if (user.email) {
            email = user.email;
          }
          if (user.phoneNumber) {
            phone_number = user.phoneNumber;
          }
          if (user.photoURL) {
            photo_url = user.photoURL;
          }

          let baseObj = {
            uuid: auth.currentUser.uid,
            email: email,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone_number,
            photo_url: photo_url,
            is_backup: true,
          };

          await updateUserProfile(auth.currentUser.uid, baseObj);

          if (photo_url) {
            setPhotoSource(photo_url);
          }
          setUserData((prevState) => ({ ...prevState, ...baseObj }));
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  const ExtraSmallScreen = useMediaPredicate("(max-width: 599px)");

  // useEffect(() => {
  //     if (!hasLoggedVisit) {
  //         logEvent(analytics, 'page_view', { page_title: 'Profile' });
  //         setHasLoggedVisit(true);
  //       }
  // }, [hasLoggedVisit]);
  //End section

  const handleAlertMessage = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const toggleDisabled = () => {
    if (!isDisabled) {
      retrieveUserData();
      setIsUserDataValid((prevUserData) => ({
        ...prevUserData,
        first_name: true,
        last_name: true,
        middle_initial: true,
        company: true,
        email: true,
        phone_number: true,
        address: true,
        city: true,
        state: true,
        zip: true,
      }));
    }
    setIsDisabled(!isDisabled);
  };

  const updateUserData = (event, identification) => {
    let target;
    let value;
    let id;
    if (!identification) {
      target = event.target;
      value = target.value;
      id = target.id;
    } else {
      id = identification;
      value = event;
    }

    if (validateFields(id, value)) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [id]: value,
      }));
    }
  };

  const validateFields = (target, value) => {
    switch (target) {
      case "first_name":
        if (value && (value.length <= 2 || value.length >= 100)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            first_name: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            first_name: true,
          }));
          return true;
        }
      case "last_name":
        if (value && (value.length <= 2 || value.length >= 100)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            last_name: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            last_name: true,
          }));
          return true;
        }
      case "middle_initial":
        if (value && value.length !== 1) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            middle_initial: false,
          }));
          return false;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            middle_initial: true,
          }));
          return true;
        }
      case "company":
        if (value && (value.length <= 2 || value.length >= 100)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            company: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            company: true,
          }));
          return true;
        }
      case "email":
        if (value && !validateEmail(value)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            email: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            email: true,
          }));
          return true;
        }
      case "phone_number":
        if (value && !validatePhoneNumber(value)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            phone_number: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            phone_number: true,
          }));
          return true;
        }
      case "address":
        if (value && !validateStreetAddress(value)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            address: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            address: true,
          }));
          return true;
        }
      case "city":
        return true;
      case "state":
        return true;
      case "zip":
        if (value && !validateZipCode(value)) {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            zip: false,
          }));
          return true;
        } else {
          setIsUserDataValid((prevUserData) => ({
            ...prevUserData,
            zip: true,
          }));
          return true;
        }

      default:
        console.log("No target specified");
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (
        isUserDataValid["first_name"] &&
        isUserDataValid["last_name"] &&
        isUserDataValid["middle_initial"] &&
        isUserDataValid["email"] &&
        isUserDataValid["phone_number"] &&
        isUserDataValid["address"] &&
        isUserDataValid["city"] &&
        isUserDataValid["state"] &&
        isUserDataValid["zip"]
      ) {
        await updateUserProfile(auth.currentUser.uid, userData);
        handleAlertMessage("Profile Updated!", "success");
        toggleDisabled();
      } else {
        handleAlertMessage("Invalid fields detected", "error");
      }
    } catch (err) {
      handleAlertMessage("There was an error updating your profile", "error");
    }
  };

  const retrieveUserData = async () => {
    setIsLoading(true);
    try {
      const savedUserData = await getUserProfile(auth.currentUser.uid);

      if (savedUserData) {
        if (savedUserData["photo_url"]) {
          setPhotoSource(savedUserData["photo_url"]);
        }
        setUserData((prevState) => ({ ...prevState, ...savedUserData }));
      } else {
        const isBackup = await retrieveUserDataBackup();
        if (!isBackup) {
          handleAlertMessage("Unable to retrieve profile", "error");
        }
      }
    } catch (error) {
      console.log("Error retrieving user data:", error);
      handleAlertMessage("Error retrieving user data", "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (isAuth && !isLoadingAuth) {
      retrieveUserData();
      if (
        auth.currentUser.providerData.some((p) => p.providerId === "google.com")
      ) {
        setIsSignedInWithGoogle(true);
      }
    }
    if (!isLoadingAuth) {
      console.log(isAuthVerified);
      if (!isAuthVerified) {
        setIsUserInvalid(true);
      }
    }
  }, [isLoadingAuth]);

  const sendResetLink = async () => {
    if (auth.currentUser.email == null || auth.currentUser.email == "") {
      handleAlertMessage("Invalid Email", "error");
    } else {
      sendPasswordResetEmail(auth, auth.currentUser.email)
        .then(() => {
          // Password reset email sent!
          // ..
          setIsResetOpen(false);
          handleAlertMessage("Please check your email", "success");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          handleAlertMessage(error.message, "error");
          // ..
        });
    }
  };

  return (
    <div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 150,
            marginBottom: 150,
          }}
        >
          <CircularProgress style={{ width: 100, height: 100 }} />
        </div>
      )}
      {isAuth && !isLoadingAuth && !isLoading && (
        <div
          style={{
            paddingTop: 50,
            paddingBottom: 50,
            backgroundColor: "#f5f5f5",
          }}
        >
          <Container>
            <Row>
              <Col
                style={{ marginBottom: 75 }}
                xs="12"
                sm="12"
                md="12"
                lg="4"
                xl="4"
              >
                <Card className="px-4 py-4" style={{ borderRadius: 15 }}>
                  <Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {photoSource && (
                        <img
                          referrerPolicy="no-referrer"
                          style={{
                            width: 250,
                            height: 250,
                            borderRadius: "50%",
                          }}
                          src={photoSource}
                          alt="Profile"
                        ></img>
                      )}
                      {!photoSource && (
                        <AccountCircleIcon
                          style={{
                            width: 250,
                            height: 250,
                            borderRadius: "50%",
                          }}
                        ></AccountCircleIcon>
                      )}
                    </div>
                  </Row>
                  <Row style={{ marginTop: 25 }}>
                    {isUserInvalid && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 0,
                        }}
                      >
                        <Button
                          onClick={() => setIsEmailVerificationOpen(true)}
                          style={{
                            borderBottom: "none",
                            padding: 15,
                            width: 250,
                            borderRadius: 0,
                          }}
                          variant="outlined"
                          startIcon={<EmailIcon />}
                        >
                          Verify Email
                        </Button>
                      </div>
                    )}
                    {!isSignedInWithGoogle && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 0,
                        }}
                      >
                        <Button
                          onClick={() => setIsResetOpen(true)}
                          style={{
                            borderBottom: "none",
                            padding: 15,
                            width: 250,
                            borderRadius: 0,
                          }}
                          variant="outlined"
                          startIcon={<KeyIcon />}
                        >
                          Change Password
                        </Button>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 0,
                      }}
                    >
                      <Button
                        href="/"
                        onClick={() => signOut(auth)}
                        style={{ padding: 15, width: 250, borderRadius: 0 }}
                        variant="outlined"
                        startIcon={<LogoutIcon />}
                      >
                        Logout
                      </Button>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="12" lg="8" xl="8">
                <Row>
                  <Col>
                    <Card className="px-4 py-4" style={{ borderRadius: 15 }}>
                      <Row>
                        <Col>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h4 style={{ fontWeight: "bold" }}>
                              {" "}
                              Personal Information
                            </h4>
                            {isDisabled && (
                              <Button
                                style={{ marginLeft: "auto" }}
                                variant="contained"
                                onClick={toggleDisabled}
                              >
                                Edit Details
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="12"
                          sm="5"
                          md="5"
                          lg="5"
                          xl="5"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="first_name"
                            name="first_name"
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            value={userData["first_name"]}
                            disabled={isDisabled}
                            error={!isUserDataValid["first_name"]}
                            onChange={updateUserData}
                            // placeholder="Johnny "
                            InputLabelProps={{ shrink: true }}
                          />
                        </Col>
                        <Col
                          xs="12"
                          sm="2"
                          md="2"
                          lg="2"
                          xl="2"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="middle_initial"
                            name="middle_initial"
                            label="Middle Initial"
                            variant="outlined"
                            fullWidth
                            value={userData["middle_initial"]}
                            disabled={isDisabled}
                            error={!isUserDataValid["middle_initial"]}
                            onChange={updateUserData}
                            // placeholder="D"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Col>
                        <Col
                          xs="12"
                          sm="5"
                          md="5"
                          lg="5"
                          xl="5"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            value={userData["last_name"]}
                            disabled={isDisabled}
                            error={!isUserDataValid["last_name"]}
                            onChange={updateUserData}
                            // placeholder="Appleseed"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            variant="outlined"
                            type="email"
                            fullWidth
                            value={userData["email"]}
                            disabled={true}
                            error={!isUserDataValid["email"]}
                            onChange={updateUserData}
                            // placeholder="Jappleseed@gmail.com"
                            InputLabelProps={{
                              inputMode: "email",
                              shrink: true,
                            }}
                          />
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="phone_number"
                            name="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            type="tel"
                            fullWidth
                            value={userData["phone_number"]}
                            disabled={isDisabled}
                            error={!isUserDataValid["phone_number"]}
                            onChange={updateUserData}
                            InputLabelProps={{ inputMode: "tel", shrink: true }}
                            // placeholder="908-765-0987"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="address"
                            name="address"
                            label="Address"
                            variant="outlined"
                            fullWidth
                            disabled={isDisabled}
                            error={!isUserDataValid["address"]}
                            value={userData["address"]}
                            onChange={updateUserData}
                            InputLabelProps={{ shrink: true }}
                            // placeholder="6 Blueberry Lane"
                          />
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="city"
                            name="city"
                            label="City"
                            variant="outlined"
                            fullWidth
                            disabled={isDisabled}
                            error={!isUserDataValid["city"]}
                            value={userData["city"]}
                            onChange={updateUserData}
                            InputLabelProps={{ shrink: true }}
                            // placeholder="Austin"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          xl="6"
                          style={{ marginTop: 20 }}
                        >
                          <Autocomplete
                            id="state"
                            name="state"
                            label="State"
                            variant="outlined"
                            fullWidth
                            disabled={isDisabled}
                            error={!isUserDataValid["state"]}
                            value={userData["state"]}
                            onChange={(event, selectedOption) => {
                              updateUserData(selectedOption.value, "state");
                            }}
                            disablePortal={true}
                            options={ALLSTATES}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // placeholder="TX"
                                InputLabelProps={{ shrink: true }}
                                id="state"
                                name="state"
                                label="State"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </Col>
                        <Col
                          xs="12"
                          sm="12"
                          md="6"
                          lg="6"
                          xl="6"
                          style={{ marginTop: 20 }}
                        >
                          <TextField
                            id="zip"
                            name="zip"
                            label="Zip"
                            variant="outlined"
                            fullWidth
                            disabled={isDisabled}
                            error={!isUserDataValid["zip"]}
                            value={userData["zip"]}
                            onChange={updateUserData}
                            InputLabelProps={{ shrink: true }}
                            // placeholder="78701"
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                {!isDisabled && (
                  <Row>
                    <Col style={{ marginTop: 20 }}>
                      <Card className="px-4 py-4" style={{ borderRadius: 15 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!isDisabled && (
                            <Button
                              style={{ float: "left" }}
                              variant="contained"
                              onClick={toggleDisabled}
                            >
                              Cancel
                            </Button>
                          )}
                          {!isDisabled && (
                            <Button
                              style={{ marginLeft: "auto" }}
                              variant="contained"
                              onClick={handleSaveChanges}
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>

          <Dialog open={isResetOpen} onClose={() => setIsResetOpen(false)}>
            <DialogTitle>Password Reset</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you would like to reset your password?
              </DialogContentText>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 25,
                }}
              >
                <Button
                  variant="contained"
                  style={{ height: 50, width: 150, marginRight: 15 }}
                  onClick={() => setIsResetOpen(false)}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  style={{ height: 50, width: 150 }}
                  onClick={sendResetLink}
                >
                  Yes
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsResetOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={isEmailVerificationOpen}>
            <DialogTitle>Please verify your email</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Click the button below to send a verification email.
              </DialogContentText>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                {!isEmailSent && (
                  <Button
                    style={{ width: "50%" }}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      sendEmailVerification(auth.currentUser);
                      setIsEmailSent(true);
                    }}
                  >
                    Send Email
                  </Button>
                )}
                {isEmailSent && <h5> Please check your inbox.</h5>}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}

      {isUpdateLoading && (
        <div className="overlay">
          <CircularProgress style={{ width: 100, height: 100 }} />
        </div>
      )}
    </div>
  );
}

export default Profile;
